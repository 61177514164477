import axios from '@/utils/http'
/* 登录 */
export const login = (params = {}) => axios.post('/api/auth/login', params)

/* 退出 */
// export const logout = (params = {}) => axios.post('/api/auth/logout')
export const logout = (id) => {
  return axios({
    method: 'GET',
    url: '/api/auth/logout?id=' + id
  })
}

/* 生成验证码 */
export const authCode = (params = {}) => axios.get('/api/auth/code', { params })

/* 用户信息 */
export const userInfo = (params = {}) => axios.get('/api/auth/getUserInfo', { params })

/* 获取动态路由 */
export const getPermissions = (params = {}) => axios.get('/api/auth/getPermissions', { params })

/* 获取动态按钮 */
export const getMenuPermission = (params = {}) => axios.get('/api/auth/getMenuPermission', { params })

export const upload = (params = {}) => axios.post('https://www.imgurl.org/upload/aws_s3', params)
