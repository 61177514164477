import { getPermissions } from '@/api/auth/login'

export default {
  namespaced: true,
  state: () => ({
    routers: [],
    menu: [],
    crumbs: [], // 面包屑
    routersFlag: true // 默认进入挂载路由
  }),
  mutations: {
    /* 设置路由 */
    setRouters (state, data) {
      state.routers = data
    },
    /* 设置菜单 */
    setMenu (state, data) {
      state.menu = data
    },
    /* 设置面包屑 */
    setcrumbs (state, data) {
      state.crumbs = data
    },
    setRoutersFlag (state, flag) {
      state.routersFlag = flag
    }
  },
  actions: {
    async permissions (context, payload) {
      return await getPermissions(payload)
    }
  }
}
