/**
 * v-hasPermi 操作权限处理
 */

import store from '@/store/index.ts'

export const hasPermi = {
  mounted (el, binding, vnode) {
    const { value } = binding
    const allPermission = '*:*:*'
    let permissions = []
    if (JSON.parse(localStorage.getItem('BUTTON_permissions') || '') && JSON.parse(localStorage.getItem('BUTTON_permissions') || '').permissions) {
      permissions = JSON.parse(localStorage.getItem('BUTTON_permissions') || '').permissions
    }
    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value
      const hasPermissions = permissions.some(permission => {
        return allPermission === permission || permissionFlag.includes(permission)
      })

      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('请设置操作权限标签值')
    }
  }
}
