import {createStore} from 'vuex'
import userInfo from './module/userInfo'
import business from './module/business'
import permissions from './module/permissions'
import getters from "*";

export default createStore({
  state: {
    isCollapse: false, // 侧边栏展开收起
   // baeUrl: 'http://localhost:18089', // 本地地址
       baeUrl:'https://chain2.1.ghatgsupply.com', // 服务器地址8089 正式环境
    // baeUrl:'http://ghltest.mxktech.cn:8089', // 服务器地址 测试地址
    // baeUrl:'http://114.115.147.95:8089',

    // oaUrl: 'http://oa.com', //本地环境
   // domain: 'http://localhost:18089' //本地环境域名
    // oaUrl: 'https://oa.ghatgsupply.com', //正式环境老系统域名
      domain: 'https://chain2.1.ghatgsupply.com' //正式环境域名

    // domain: 'http://ghltest.mxktech.cn' //测试环境域名

  },
  mutations: {
    setIsCollapse(state) {
      state.isCollapse = !state.isCollapse
    }
  },
  getters: {},
  actions: {},
  modules: {
    userInfo,
    permissions,
    business
  }

})
