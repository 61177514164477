import { getMenuPermission } from '@/api/auth/login'

export default {
  namespaced: true,
  state: () => ({
    // userInfo: JSON.parse(getCookie('userInfo') || '{}') || {}
    userInfo: []
  }),
  mutations: {
    SETUSERINFO (state, data) {
      state.userInfo = data
    }
  },
  actions: {
    async userInfo (context, payload) {
      return await getMenuPermission(payload)
    }
  }
}
