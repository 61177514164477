import axios from 'axios'
import Cookies from 'js-cookie'
import { ElMessage, ElLoading } from 'element-plus'
import router from '../router/index'
import fileDownload from 'js-file-download'
import { useStore } from 'vuex'

const store = useStore()
let toast = null // loading加载。。。
let timeId = null
let loadingCount = 0
const delayLoading = 200

/* 开启loading 200ms之内返回数据不开启 loading */
function setDelayLoading () {
  clearTimeout(timeId)
  timeId = setTimeout(() => {
    toast = ElLoading.service({
      lock: true,
      fullscreen: true,
      text: '加载中...',
      background: 'transparent'
    })
    clearTimeout(timeId)
  }, delayLoading)
}

/* clear loading */
function clearLoading () {
  clearTimeout(timeId)
  if (toast) {
    toast.close()
  }
}

axios.interceptors.request.use(function (config) {
  //config.baseURL = ''
  // config.timeout = 50000 // 请求超时
  config.timeout = 7200000 // 请求超时
  /* 请求加1 */
  setDelayLoading()
  loadingCount++
  /* 携带Token */
  if (router.currentRoute.value.path !== '/login' || config.url === '/api/auth/getUserInfo' || config.url === '/api/auth/getPermissions') {
    config.headers.Authorization = Cookies.get('token')
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  var enc = new TextDecoder("utf-8")
  var uint8_msg = new Uint8Array(response.data)
  if (enc.decode(uint8_msg)) {
    try {
      // 如果能转为json对象 说明后台返回的是json  否则是返回文件流  走catch
      const data = JSON.parse(enc.decode(uint8_msg))
      ElMessage.error({
        message: data.message
      })
      loadingCount--
      if (loadingCount == 0) {
        clearLoading()
      }
      return response
    } catch (err) {
      const fileName = response.headers['content-disposition'] // res.headers['content-disposition'].match(/fushun(\S*)xls/)[0];
      if (typeof fileName !== 'undefined') {
        loadingCount--
        if (loadingCount == 0) {
          clearLoading()
        }
        const filename2 = fileName.split("=")[1]
        const file = decodeURIComponent(filename2)
        // eslint-disable-next-line no-undef
        fileDownload(response.data, file)
        return response
      }
    }
  }

  /* 请求减1 */
  loadingCount--
  if (loadingCount == 0) {
    clearLoading()
  }
  const {
    code,
    message
  } = response.data
  if (code !== 200) {
    ElMessage.error({
      message: message
    })
    if (code === 401) {
      Cookies.remove('token')
      // this.$router.push("/login")
      const url = window.location.href
      if (url.includes('localhost')) {
        window.location.href = 'http://localhost:8080/#/login'
      } else if (url.includes('ghltest')) {
        window.location.href = 'http://ghltest.mxktech.cn/#/login'
      } else {
        window.location.href = 'https://chain2.1.ghatgsupply.com/#/login'
      }
      this.$message.closeAll()
    }
  }
  return response.data
}, function (error) {
    /* 请求减1 */
    loadingCount--
    if (loadingCount == 0) {
      clearLoading()
    }
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break
      case 401:
        error.message = '未授权，请重新登录'
        break
      case 403:
        error.message = '拒绝访问'
        break
      case 404:
        error.message = '请求错误,未找到该资源'
        break
      case 405:
        error.message = '请求方法未允许'
        break
      case 408:
        error.message = '请求超时'
        break
      case 500:
        error.message = '服务器端出错'
        break
      case 501:
        error.message = '网络未实现'
        break
      case 502:
        error.message = '网络错误'
        break
      case 503:
        error.message = '服务不可用'
        break
      case 504:
        error.message = '网络超时'
        break
      case 505:
        error.message = 'http版本不支持该请求'
        break
      default:
        error.message = `未知错误${error.response.status}`
    }
  } else {
    // error.messages = "连接到服务器失败";
  }

  // 发生错误关闭loading
  clearTimeout(timeId)
  if (toast) {
    toast.close()
  }

  // 取消发送请求的错误
  if (axios.isCancel(error)) {
    ElMessage.error({
      message: error.message
    })
    return
  }

  ElMessage.error({
    message: error.message
  })

  return Promise.reject(error)
})

export default axios
