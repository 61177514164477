export default {
  namespaced: true,
  state: () => ({
    business: {
      listType: 1
    }
  }),
  mutations: {
    SETINFO (state, data) {
      state.business = data
    }
  }
}
