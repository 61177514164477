import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.scss' // 重置样式
import element from 'element-plus'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message-box.css'
import * as ElIcons from '@element-plus/icons-vue'
import { vLoading } from 'element-plus/es/components/loading/src/directive'
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import * as directives from './directive' // 把所有的directive都引入进来

const app = createApp(App)
app.config.warnHandler = () => null

app.directive('load', vLoading)
Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key])
})

for (const name in ElIcons) {
  app.component(name, (ElIcons as any)[name])
}

app.use(store).use(router).use(element, { locale }).mount('#app')

// 全局修改默认配置，点击空白处不能关闭弹窗
app._context.components.ElDialog["props"].closeOnClickModal.default = false
